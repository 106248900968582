@import url("./ui/foundations/colors.css");
@import url("./ui/foundations//elevation.css");
@import url("./ui/foundations/numbers.css");
@import url("./ui/foundations/animations.css");

* {
    box-sizing: border-box;
}

.local-viewer .agora_video_player {
    object-fit: cover !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    z-index: 0 !important;
}

/* #agora-video-player-track-cam-d7c0da5c {
    height: 100dvh !important;
    position: static !important;
    display: flex !important;
    align-items: center !important;


} */

/* .agora_video_player {
    transform: rotateY(0) !important;
    height: auto !important;
    aspect-ratio: 1.333 !important;
    position: relative !important;

} */
