@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f548;
  }
  100% {
    background-color: #e0e0e0;
  }
}
